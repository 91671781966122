<template>
  
    <form role="form" @submit="onFormSubmit" name="route-form">

      <div class="row" v-if="showCreateFrom">
        <div class="col-lg-12">
          <h3>{{ getLocalizedText(labels.createFromTitle) }}</h3>
        </div>
      </div>
      <Select 
        v-if="showCreateFrom"
        :selectOptions="{
          options: listRoutes,
          getOptionLabel : route => route.name
        }"
        v-bind:value.sync="createFromForm.route" 
        id="createFromRoute" 
        :label="getLocalizedText(labels.createFromRoute)" 
        :placeholder="getLocalizedText(labels.createFromRoute_placeholder)"
        labelAsColumn 
      >
        <template v-slot:buttons>
            <button class="btn btn-primary create-from" type="button" @click="createFromRoute()">{{ getLocalizedText(labels.createFromRouteButton) }}</button>
        </template>
      </Select>

      <div>

        <TextField 
          ref="updateName"
          v-if="options.allowChangeName"
          v-bind:value.sync="routeForm.name" 
          id="updateName" 
          :label="getLocalizedText(labels.name)" 
          :placeholder="getLocalizedText(labels.name_placeholder)" 
          :labelAsColumn="true"
          :required="true"
        />
        <TextField 
            ref="updatePath"
            v-bind:value.sync="routeForm.path" 
            id="updatePath" 
            :label="getLocalizedText(labels.path)" 
            :placeholder="getLocalizedText(labels.path_placeholder)" 
            :labelAsColumn="true"
            :required="true"
        />
        <SwitchCheck 
          v-bind:value.sync="routeForm.loggedUsers" 
          id="loggedUsers" 
          :label="getLocalizedText(labels.loggedUsers)" 
          :labelAsColumn="true"
        />

        <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{buttonLabel}}</button>

      </div>
    </form>

</template>


<script lang="ts">
import { toRefs, Ref, ComputedRef, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import TextField from '@fwk-client/components/panels/input/TextField.vue';
import Select from '@fwk-client/components/panels/input/Select.vue';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';

import * as Ladda from 'ladda';

import { useSiteAdmin } from '../../../composables/useSiteAdmin';
import { useRouteAdmin } from '../../../composables/useRouteAdmin';

export interface RouteFormOptions {
  allowChangeName:boolean
}

export default defineComponent({
  props: {
      route: {
        type: Object as PropType<any>,
        required: false
      },
      options: {
        type: Object as PropType<RouteFormOptions>,
        required: false
      }
  },
  components: {
    TextField, Select, SwitchCheck
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();
    const { sites, selectedSite, selectedSiteTopLevelDomain, listRoutes } = useSiteAdmin(props, context);
    const { updateRouteFormForUpdate, routeForm, createRoute, updateRoute
      } = useRouteAdmin(props, context);

    const { route, options } = toRefs(props);

    const createFromForm:Ref<any> = ref({});    

    var computedOptions:Ref<RouteFormOptions> = computed(() => {
      return {
        allowChangeName: (options.value != undefined && options.value.allowChangeName != undefined) ? options.value!.allowChangeName : true
      }
    })

    var laddaSubmit:Ladda.LaddaButton|null = null;

    const labels = {
      "routeName" : {
        "fr" : "Page",
        "en" : "Page"
      },
      "routeName_placeholder" : {
        "fr" : "Sélectionnez une page",
        "en" : "Select a page"
      },
      "createFromTitle": {
        "fr" : "Copier une page existante",
        "en" : "Copy an existing page"
      },
      "createFromRoute": {
        "fr" : "Page existante",
        "en" : "Existing page"
      },
      "createFromRoute_placeholder" : {
        "fr" : "Sélectionnez une page",
        "en" : "Select a page"
      },
      "createFromRouteButton" : {
        "fr" : "Copier",
        "en" : "Copy"
      },
      "name" : {
        "fr" : "Nom",
        "en" : "Name"
      },
      "name_placeholder" : {
        "fr" : "Nom",
        "en" : "Name"
      },
      "path" : {
        "fr" : "Chemin",
        "en" : "Path"
      },
      "path_placeholder" : {
        "fr" : "Chemin",
        "en" : "Path"
      },
      "loggedUsers" : {
        "fr" : "Visible uniquement aux utilisateurs connectés",
        "en" : "Available only for logged user"
      }
    }

    const availableLanguageCodes = ['fr','en'];

    const showCreateFrom = computed(() => {
      return (route == undefined || route.value == undefined || route.value.path == "") && listRoutes.value.length > 0;
    })

    const availableComponents:Ref<any[]> = ref([]);

    const createFromRoute = () => {
      if(createFromForm.value.route) {
        updateRouteFormForUpdate(createFromForm.value.route);
      }
    }

    const buttonLabel = computed(() => {
      if(route && route.value && route.value.path != "") { return app.$t('cms.site.routes.components.update.button') }
      else { return app.$t('cms.site.routes.components.create.button'); }
    })

    onMounted(() => {
      if(props.route && props.route.path != "") {
        var button:HTMLButtonElement|null = document.querySelector( 'form[name=route-form] button' );
        laddaSubmit = Ladda.create(button!);
      }
    })

    if(props.route && props.route.path != "") {
      updateRouteFormForUpdate(route.value);
    }

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();

      if(route && route.value) {
        updateRoute().then((result:any) => {
          if(result.updated) {
            context.emit('route-updated', result);
          }
          laddaSubmit!.stop();
        })
      }
      else {
        createRoute().then((result:any) => {
          if(result.created) {  
            context.emit('route-created', result);
          }
          laddaSubmit!.stop();
        })
      }
    }

    watch(
      () => routeForm.path,
      (val:any, oldVal:any) => {
        if(val != undefined && val != "") {
          var button:HTMLButtonElement|null = document.querySelector( 'form[name=route-form] button' );
          laddaSubmit = Ladda.create(button!);
        }
      },
      { deep: true }
    )

    watch(
      route,
      (val:any, oldVal:any) => {
        console.log("ROUTE FORM - ON ROUTE UPDATE")
        
        if(laddaSubmit) { laddaSubmit!.stop(); }
        
        if(val != undefined && val.path != "") {
          updateRouteFormForUpdate(val);
        }
      }
    )

    return {
      labels,
      routeForm,
      listRoutes,
      showCreateFrom,
      availableComponents,
      onFormSubmit,
      buttonLabel,
      createFromForm,
      createFromRoute,
      options:computedOptions
    }
  }
})
</script>